<template>
    <div class="imprint text-view">
        <v-container fluid>
            <v-row align="start" justify="start" class="mt-0">
                <v-col cols="12">
                    <h1 class="text-h4">
                        Datenschutzerklärung
                    </h1>
                    <p>
                        In dieser Datenschutzerklärung informieren wir (BEN digital services GmbH) 
                        Sie über die Verarbeitung
                        personenbezogener Daten bei der Nutzung unserer Website
                        (lea.legal).
                    </p>
                    <p>
                        Personenbezogene Daten sind Informationen, die sich auf
                        eine identifizierte oder identifizierbare Person
                        beziehen. Hierunter fallen vor allem Angaben, die
                        Rückschlüsse auf Ihre Identität ermöglichen,
                        beispielsweise Ihr Name, Ihre Telefonnummer, Ihre
                        Anschrift oder E-Mail-Adresse. Statistische Daten, die
                        wir beispielsweise bei einem Besuch unserer Website
                        erheben und die nicht mit Ihrer Person in Verbindung
                        gebracht werden können, fallen nicht unter den Begriff
                        des personenbezogenen Datums.
                    </p>
                    <p>
                        Sie können diese Datenschutzerklärung ausdrucken oder
                        speichern, indem Sie die übliche Funktionalität Ihres
                        Browsers nutzen.
                    </p>

                    <h2 class="text-h5 mt-2">
                        1. Ansprechpartner
                    </h2>
                    <p>
                        Ansprechpartner und sogenannter Verantwortlicher für die
                        Verarbeitung Ihrer personenbezogenen Daten bei Besuch
                        dieser Website im Sinne der
                        EU-Datenschutzgrundverordnung (DSGVO) ist die
                    </p>
                    <p>
                        BEN digital services GmbH<br />
                        Schloßstr. 19<br />
                        82031 Grünwald<br /><br />
                        Telefon: +49 721 754 052 70<br />
                        E-Mail: info@bendigital.de<br />
                    </p>
                    <p>
                        Für alle Fragen zum Thema Datenschutz in Zusammenhang
                        mit unseren Produkten oder der Nutzung unserer Website
                        können Sie sich jederzeit auch an unseren
                        Datenschutzbeauftragten wenden. Dieser ist unter obiger
                        postalischen Adresse sowie unter der zuvor angegeben
                        E-Mail-Adresse (Stichwort: „z. Hd.
                        Datenschutzbeauftragter“) erreichbar.
                    </p>

                    <h2 class="text-h5 mt-2">
                        2. Datenverarbeitung auf unserer Website
                    </h2>
                    <h3 class="text-subtitle-1 mt-2">
                        2.1. Aufruf unserer Website / Zugriffsdaten
                    </h3>
                    <p>
                        Bei jeder Nutzung unserer Website erheben wir die
                        Zugriffsdaten, die Ihr Browser automatisch übermittelt,
                        um Ihnen den Besuch der Website zu ermöglichen. Die
                        Zugriffsdaten umfassen insbesondere:
                    </p>
                    <ul>
                        <li>IP-Adresse des anfragenden Geräts</li>
                        <li>Datum und Uhrzeit der Anfrage</li>
                        <li>
                            Adresse der aufgerufenen Website und der anfragenden
                            Website
                        </li>
                        <li>
                            Angaben über den verwendeten Browser und das
                            Betriebssystem
                        </li>
                        <li>
                            Online-Kennungen (z. B. Gerätekennungen,
                            Session-IDs)
                        </li>
                    </ul>
                    <p>
                        Die Datenverarbeitung dieser Zugriffsdaten ist
                        erforderlich, um den Besuch der Website zu ermöglichen
                        und um die dauerhafte Funktionsfähigkeit uns Sicherheit
                        unserer Systeme zu gewährleisten. Die Zugriffsdaten
                        werden zu den zuvor beschriebenen Zwecken zudem
                        zeitweise in internen Logfiles gespeichert, um
                        statistische Angaben über die Nutzung unserer Website zu
                        erstellen, um unsere Website im Hinblick auf die
                        Nutzungsgewohnheiten unserer Besucher weiterzuentwickeln
                        (z. B. wenn der Anteil mobiler Geräte steigt, mit denen
                        die Seiten abgerufen werden) und um unsere Website
                        allgemein administrativ zu pflegen. Rechtsgrundlage ist
                        Art. 6 Abs. 1 S. 1 lit. b DSGVO.
                    </p>
                    <p>
                        Die in den Logfiles gespeicherten Informationen lassen
                        keinen unmittelbaren Rückschluss auf Ihre Person zu –
                        insbesondere speichern wir die IP-Adressen lediglich in
                        gekürzter, anonymisierter Form. Die Logfiles werden für
                        30 Tage gespeichert und nach anschließender
                        Anonymisierung archiviert.
                    </p>

                    <h3 class="text-subtitle-1 mt-2">
                        2.2. Kontaktaufnahme
                    </h3>
                    <p>
                        Sie haben verschiedene Möglichkeiten, um mit uns in
                        Kontakt zu treten. Hierzu gehören das Kontaktformular,
                        der Live-Chat, die Anmeldung zur Veranstaltungen oder
                        die Rückruffunktion. In diesem Zusammenhang verarbeiten
                        wir Daten ausschließlich zum Zwecke der Kommunikation
                        mit Ihnen. Die Rechtsgrundlage ist Art. 6 Abs. 1 lit. b
                        DSGVO. Die bei der Verwendung des Kontaktformulars von
                        uns erhobenen Daten werden nach vollständiger
                        Bearbeitung Ihrer Anfrage automatisch gelöscht, es sei
                        denn wir benötigen Ihre Anfrage noch zur Erfüllung
                        vertraglicher oder gesetzlicher Pflichten (vgl.
                        Abschnitt „Speicherdauer“).
                    </p>

                    <h3 class="text-subtitle-1 mt-2">
                        2.3. Registrierung
                    </h3>
                    <p>
                        Sie haben die Möglichkeit, sich für unseren
                        Login-Bereich zu registrieren, um den vollen
                        Funktionsumfang unserer Website nutzen zu können. Die
                        von Ihnen verpflichtend anzugebenen Daten haben wir
                        durch eine Kennzeichnung als Pflichtfelder
                        hervorgehoben. Ohne diese Daten ist eine Registrierung
                        nicht möglich. Rechtsgrundlage der Verarbeitung ist Art.
                        6 Abs. 1 lit. b DSGVO.
                    </p>

                    <h3 class="text-subtitle-1 mt-2">
                        2.4. Einsatz eigener Cookies
                    </h3>
                    <p>
                        Für einen Teil unserer Dienste ist es erforderlich, dass
                        wir sogenannte Cookies einsetzen. Ein Cookie ist eine
                        kleine Textdatei, die durch den Browser auf ihrem Gerät
                        gespeichert wird. Cookies werden nicht dazu eingesetzt,
                        um Programme auszuführen oder Viren auf Ihren Computer
                        zu laden. Hauptzweck unserer eigenen Cookies ist
                        vielmehr, ein speziell auf Sie zugeschnittenes Angebot
                        bereitzustellen und die Nutzung unserer Services so
                        zeitsparend wie möglich zu gestalten.
                    </p>
                    <p>Wir verwenden eigene Cookies insbesondere</p>
                    <ul>
                        <li>zur Login-Authentifizierung;</li>
                        <li>zur Lastverteilung;</li>
                        <li>um Ihre Spracheinstellungen zu speichern;</li>
                        <li>
                            um zu vermerken, dass Ihnen eine auf unserer Website
                            platzierte Informationen angezeigt wurde ¬– sodass
                            diese beim nächsten Besuch der Website nicht erneut
                            angezeigt wird.
                        </li>
                    </ul>
                    <p>
                        Wir wollen Ihnen dadurch eine komfortablere und
                        individuellere Nutzung unserer Website ermöglichen.
                        Diese Service-Leistungen beruhen auf unseren
                        vorgenannten berechtigten Interessen, Rechtsgrundlage
                        ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                    </p>

                    <h2 class="text-h5 mt-2">
                        3. Weitergabe von Daten
                    </h2>
                    <p>
                        Eine Weitergabe der von uns erhobenen Daten erfolgt
                        grundsätzlich nur, wenn:
                    </p>
                    <ul>
                        <li>
                            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                            ausdrückliche Einwilligung dazu erteilt haben,
                        </li>
                        <li>
                            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO
                            zur Geltendmachung, Ausübung oder Verteidigung von
                            Rechtsansprüchen erforderlich ist und kein Grund zur
                            Annahme besteht, dass Sie ein überwiegendes
                            schutzwürdiges Interesse am Unterbleiben der
                            Weitergabe Ihrer Daten haben,
                        </li>
                        <li>
                            wir nach Art. 6 Abs. 1 S. 1 lit. c DSGVO zur
                            Weitergabe gesetzlich verpflichtet sind oder
                        </li>
                        <li>
                            dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1
                            lit. b DSGVO für die Abwicklung von
                            Vertragsverhältnissen mit Ihnen oder für die
                            Durchführung vorvertraglicher Maßnahmen erforderlich
                            ist, die auf Ihre Anfrage hin erfolgen.
                        </li>
                    </ul>
                    <p>
                        Ein Teil der Datenverarbeitung kann durch unsere
                        Dienstleister erfolgen. Neben den in dieser
                        Datenschutzerklärung erwähnten Dienstleistern können
                        hierzu insbesondere Rechenzentren, die unsere Website
                        und Datenbanken speichern, IT-Dienstleister, die unsere
                        System warten, sowie Beratungsunternehmen gehören.
                        Sofern wir Daten an unsere Dienstleister weitergeben,
                        dürfen diese die Daten ausschließlich zur Erfüllung
                        ihrer Aufgaben verwenden. Die Dienstleister wurden von
                        uns sorgfältig ausgewählt und beauftragt. Sie sind
                        vertraglich an unsere Weisungen gebunden, verfügen über
                        geeignete technische und organisatorische Maßnahmen zum
                        Schutz der Rechte der betroffenen Personen und werden
                        von uns regelmäßig kontrolliert.
                    </p>
                    <p>
                        Darüber hinaus kann eine Weitergabe in Zusammenhang mit
                        behördlichen Anfragen, Gerichtsbeschlüssen und
                        Rechtsverfahren erfolgen, wenn es für die
                        Rechtsverfolgung oder -durchsetzung erforderlich ist.
                    </p>

                    <h3 class="text-subtitle-1 mt-2">
                        3.1 Hosting und E-Mail Versand
                    </h3>
                    <p>
                        Die von uns in Anspruch genommenen Hosting-Leistungen
                        dienen der Zurverfügungstellung der folgenden
                        Leistungen: Infrastruktur- und
                        Plattformdienstleistungen, Rechenkapazität,
                        Speicherplatz und Datenbankdienste, E-Mail-Versand,
                        Sicherheitsleistungen sowie technische
                        Wartungsleistungen, die wir zum Zwecke des Betriebs
                        dieses Onlineangebotes einsetzen. Hierbei verarbeiten
                        wir, bzw. unser Hostinganbieter Bestandsdaten,
                        Kontaktdaten, Inhaltsdaten, Vertragsdaten,
                        Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden,
                        Interessenten und Besuchern dieses Onlineangebotes auf
                        Grundlage unserer berechtigten Interessen an einer
                        effizienten und sicheren Zurverfügungstellung dieses
                        Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m.
                        Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).
                    </p>

                    <h3 class="text-subtitle-1 mt-2">
                        3.2 Google Fonts
                    </h3>
                    <p>
                        Wir binden die Schriftarten ("Google Fonts") des
                        Anbieters Google LLC, 1600 Amphitheatre Parkway,
                        Mountain View, CA 94043, USA, ein. Datenschutzerklärung:
                        <a
                            href="https://www.google.com/policies/privacy/"
                            target="_blank"
                            rel="noopener"
                            >https://www.google.com/policies/privacy/</a
                        >, Opt-Out:
                        <a
                            href="https://adssettings.google.com/authenticated"
                            target="_blank"
                            rel="noopener"
                            >https://adssettings.google.com/authenticated</a
                        >.
                    </p>

                    <h2 class="text-h5 mt-2">
                        4. Speicherdauer
                    </h2>
                    <p>
                        Grundsätzlich speichern wir personenbezogene Daten nur
                        solange, wie zur Erfüllung vertraglicher oder
                        gesetzlicher Pflichten erforderlich, zu denen wir die
                        Daten erhoben haben. Danach löschen wir die Daten
                        unverzüglich, es sei denn, wir benötigen die Daten noch
                        bis zum Ablauf der gesetzlichen Verjährungsfrist zu
                        Beweiszwecken für zivilrechtliche Ansprüche oder wegen
                        gesetzlicher Aufbewahrungspflichten.
                    </p>
                    <p>
                        Zu Beweiszwecken müssen wir Vertragsdaten noch drei
                        Jahre ab Ende des Jahres, in dem die
                        Geschäftsbeziehungen mit Ihnen enden, aufbewahren.
                        Etwaige Ansprüche verjähren nach der gesetzlichen
                        Regelverjährungsfrist frühestens zu diesem Zeitpunkt.
                    </p>
                    <p>
                        Auch danach müssen wir Ihre Daten teilweise noch aus
                        buchhalterischen Gründen speichern. Wir sind dazu wegen
                        gesetzlicher Dokumentationspflichten verpflichtet, die
                        sich aus dem Handelsgesetzbuch, der Abgabenordnung, dem
                        Kreditwesengesetz, dem Geldwäschegesetz und dem
                        Wertpapierhandelsgesetz ergeben können. Die dort
                        vorgegebenen Fristen zur Aufbewahrung von Unterlagen
                        betragen zwei bis zehn Jahre.
                    </p>

                    <h2 class="text-h5 mt-2">
                        5. Ihre Rechte
                    </h2>
                    <p>
                        Ihnen steht jederzeit das Recht zu, Auskunft über die
                        Verarbeitung Ihrer personenbezogenen Daten durch uns zu
                        verlangen. Wir werden Ihnen im Rahmen der
                        Auskunftserteilung die Datenverarbeitung erläutern und
                        eine Übersicht der über Ihre Person gespeicherten Daten
                        zur Verfügung stellen.
                    </p>
                    <p>
                        Falls bei uns gespeicherte Daten falsch oder nicht mehr
                        aktuell sein sollten, haben Sie das Recht, diese Daten
                        berichtigen zu lassen.
                    </p>
                    <p>
                        Sie können außerdem die Löschung Ihrer Daten verlangen.
                        Sollte die Löschung aufgrund anderer Rechtsvorschriften
                        ausnahmsweise nicht möglich sein, werden die Daten
                        gesperrt, so dass Sie nur noch für diesen gesetzlichen
                        Zweck verfügbar sind.
                    </p>
                    <p>
                        Sie können die Verarbeitung Ihrer Daten außerdem
                        einschränken lassen, z. B. wenn Sie der Auffassung sind,
                        dass die von uns gespeicherten Daten nicht korrekt sind.
                        Ihnen steht auch das Recht auf Datenübertragbarkeit zu,
                        d. h. dass wir Ihnen auf Wunsch eine digitale Kopie der
                        von Ihnen bereitgestellten personenbezogenen Daten
                        zukommen lassen.
                    </p>
                    <p>
                        Um Ihre hier beschriebenen Rechte geltenden zu machen,
                        können Sie sich jederzeit an die oben genannten
                        Kontaktdaten wenden. Dies gilt auch, sofern Sie Kopien
                        von Garantien zum Nachweis eines angemessenen
                        Datenschutzniveaus erhalten möchten.
                    </p>
                    <p>
                        Zudem haben Sie das Recht der Datenverarbeitung zu
                        widersprechen, die auf Art. 6 Abs. 1 lit. e oder f DSGVO
                        beruht. Sie haben schließlich das Recht sich bei der für
                        uns zuständigen Datenschutzaufsichtsbehörde zu
                        beschweren. Sie können dieses Recht bei einer
                        Aufsichtsbehörde in dem Mitgliedstaat Ihres
                        Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des
                        mutmaßlichen Verstoßes geltend machen. In Berlin ist die
                        zuständige Aufsichtsbehörde: Berliner Beauftragte für
                        Datenschutz und Informationsfreiheit, Friedrichstr. 219,
                        10969 Berlin.
                    </p>

                    <v-card class="my-12">
                        <v-card-text>
                            <h2 class="text-h5 mt-2">
                                6. Widerrufs- und Widerspruchsrecht
                            </h2>
                            <p>
                                Sie haben gemäß Artikel 7 Abs. 2 DSGVO das
                                Recht, eine einmal erteilte Einwilligung
                                jederzeit uns gegenüber zu widerrufen. Dies hat
                                zur Folge, dass wir die Datenverarbeitung, die
                                auf dieser Einwilligung beruhte, für die Zukunft
                                nicht mehr fortführen. Durch den Widerruf der
                                Einwilligung wird die Rechtmäßigkeit der
                                aufgrund der Einwilligung bis zum Widerruf
                                erfolgten Verarbeitung nicht berührt.
                            </p>
                            <p>
                                Soweit wir Ihre Daten auf Grundlage von
                                berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1
                                lit. f DSGVO verarbeiten, haben Sie gemäß Art.
                                21 DSGVO das Recht, Widerspruch gegen die
                                Verarbeitung Ihrer Daten einzulegen, soweit
                                dafür Gründe vorliegen, die sich aus Ihrer
                                besonderen Situation ergeben oder sich der
                                Widerspruch gegen Direktwerbung richtet. Im
                                letzteren Fall haben Sie ein generelles
                                Widerspruchsrecht, das auch ohne die Angabe von
                                Gründen von uns umgesetzt wird.
                            </p>
                            <p>
                                Möchten Sie von Ihrem Widerrufs- oder
                                Widerspruchsrecht Gebrauch machen, genügt eine
                                formlose Mitteilung an die an die oben genannten
                                Kontaktdaten.
                            </p>
                        </v-card-text>
                    </v-card>

                    <h2 class="text-h5 mt-2">
                        7. Datensicherheit
                    </h2>
                    <p>
                        Wir unterhalten aktuelle technische Maßnahmen zur
                        Gewährleistung der Datensicherheit, insbesondere zum
                        Schutz Ihrer personenbezogenen Daten vor Gefahren bei
                        Datenübertragungen sowie vor Kenntniserlangung durch
                        Dritte. Diese werden dem aktuellen Stand der Technik
                        entsprechend jeweils angepasst. Zur Sicherung der von
                        Ihnen auf unserer Website angegebenen persönlichen Daten
                        verwenden wir den Transport Layer Security (TLS), der
                        die von Ihnen eingegebenen Informationen verschlüsselt.
                    </p>

                    <h2 class="text-h5 mt-2">
                        8. Änderungen der Datenschutzerklärung
                    </h2>
                    <p>
                        Gelegentlich aktualisieren wir diese
                        Datenschutzerklärung, beispielsweise wenn wir unsere
                        Website anpassen oder sich die gesetzlichen oder
                        behördlichen Vorgaben ändern.
                    </p>
                    <p>&nbsp;</p>
                    <p class="text-right">
                        Version: 1.2 / Stand: Januar 2025
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Privacy',
};
</script>
